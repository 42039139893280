<template>
    <div class="datelistStyle">
        <b-form-input type="text" :list="listName" autocomplete="off" required :locale="locale"
                      v-model="inputValue" @change="itemChange" :placeholder="placeholder"
        ></b-form-input>
        <datalist :id="listName">
            <option v-for="(option, index) in options" :key="index" :value="option[title]">{{option[subTitle]}}</option>
        </datalist>
    </div>
</template>
<script>
    import autocompleteApi from "../../../api/autocompleteApi";

    export default {
        name: "ap-autocomplete",
        props: {
            listName: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            subTitle: {
                type: String,
                required: true,
            },
            formFieldIndex: {
                type: Number,
                required: false
            },
            url: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            locale:{
                type:String,
                required:false
            }
        },
        data() {
            return {
                inputValue: "",
                options: [],
                selectedItem: {}
            }
        },
        watch: {
            inputValue: function () {
                //当input时，若未匹配则请求后端拿数据
                if (!this.keyWordMatch(this.inputValue)) {
                    this.$nextTick(function () {
                        if(this.locale==''){
                            this.locale='en'
                        }
                        else{
                            switch (this.locale) {
                                case 'en':this.locale = 'en';
                                break;
                                case 'ch':this.locale = 'zh_HK';
                                    break;
                            }
                        }
                        // for hotel city autocomplete only
                        autocompleteApi.getAutocompleteDestination(this.url, this.inputValue,this.locale).then(res => {
                            this.options = res.data;
                            console.info("options: " + this.options);
                        });

                        //for rail city 
                        // autocompleteApi.railPlaces(this.url, this.inputValue, this.locale).then(res => {
                        //     this.options = res.data;
                        //     console.info("get rail places")
                        //     console.info("options: " + this.options);
                        // });
                    })
                }
                else {
                    this.options = [];
                }
            }
        },
        methods: {
            itemChange: function () {
                //暴露给父类传入函数
                console.info(this.selectedItem);
                console.info(this.formFieldIndex);
                this.selectedItem.formFieldIndex = this.formFieldIndex;
                this.$emit("selectedItem", this.selectedItem);
            },
            keyWordMatch: function (key) {
                if (this.options.length == 0) return false;
                let titleStr = this.title;
                let filterArr = this.options.filter(function filter(item) {
                    if (key == item[titleStr]) {
                        return true
                    } else {
                        return false
                    }
                });
                //若匹配,则设匹配值
                if (filterArr != null && filterArr.length > 0) {
                    this.selectedItem = filterArr[0];
                    return true;
                }
                return false;
            }
        },
    };
</script>

<style scoped>

</style>