<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class=" container-fluid p-4">

            <!--                建立表单-->
            <b-card bg-variant="light" style="background-color:white!important;">
                <div style="position:relative;bottom: 35px;background-color:transparent;font-weight: bolder;font-size: 20px">{{$t('hotel')}}</div>
<!--                <b-form action="hotellist">-->
                <b-form>
                    <b-form-group
                            label-cols-lg="12"
                            label=""
                            label-size="lg"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                    >
                        <ap-autocomplete @selectedItem="processDestination" list-name="hotelDestination" :locale="$i18n.locale"
                                         url="/hotel-destination" :subTitle="'cityName'" :title="'label'" :placeholder="destinationLabel"></ap-autocomplete>
                        <p style="color: red" v-show="destinationError">{{$t('validateSelect')}}</p>
                        <b-row style="margin-top: 30px">
                            <b-col md="3">
                                <b-form-datepicker :locale="$i18n.locale" id="check_in_date" v-model="check_in_date" :min="checkInMinDate" class="mb-2" placeholder="Check In Date" required></b-form-datepicker>
                            </b-col>
                            <b-col md="3">
                                <b-form-datepicker :locale="$i18n.locale" id="check_out_date" v-model="check_out_date"
                                                   placeholder="Check Out Date" :min="new Date(new Date(this.check_in_date).setDate(new Date(this.check_in_date).getDate()+1))" class="mb-2" required></b-form-datepicker>
                            </b-col>
                            <b-col md="2">
                                <b-form-select v-model="room_selected" :options="room_options" required></b-form-select>
                            </b-col>
                            <b-col md="2">
                                <b-form-select v-model="adult_selected" :options="adult_options" required></b-form-select>
                            </b-col>
                            <b-col md="2">
                                <b-form-select v-model="child_selected" :options="child_options" required></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col offset-md="5">
                                <b-button variant="danger" offset-md="4" @click="HotelCitySearch()">{{$t('searchHotel')}}</b-button>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-card>
        </div>
        <div class="c_wrap container-fluid p-4" style="margin-top: 10%" >
            <div class="c_carousel"></div>
            <carousel-3d
                    :autoplay="true"
                    :autoplayTimeout="3000"
                    :display="5"
                    :width="480"
                    :height="330"
                    :animationSpeed="1000"
            >

                <slide class="carousel_box" v-for="(item, index) in earthData" :key="index" :index="index">
                    <div class="carousel_flashlight">
                        <img :src="item.url" alt="" style="min-width: 500px;min-height: 500px">
                    </div>
                </slide>
            </carousel-3d>
        </div>
    </Layout>
</template>

<script>
    // import Customizer from '../../../layouts/full-layout/Customizer/Customizer'
    // import VerticalSidebar from '../../../layouts/full-layout/vertical-sidebar/VerticalSidebar'
    // import VerticalHeader from '../../../layouts/full-layout/vertical-header/VerticalHeader'
    // import hotelUtils from "./../../../utils/hotel/hotelUtils"
    import Layout from "../layouts/main";
    import PageHeader from "@/components/page-header";

    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import {mapState,mapGetters,  mapActions} from "vuex";
    import hotelApi from "../../api/hotelApi";
    import ApAutocomplete from "../../components/forms-element/autocomplete/ApAutocomplete";
    export default {
        name: "HotelHome",
        components:{
            Layout,
            PageHeader,
            Carousel3d,
            Slide,
            ApAutocomplete,
        },
        data(){
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            const minDate = new Date(today);
            console.log(minDate)
            minDate.setDate(minDate.getDate() + 1);
            const checkOutMinDate = new Date(new Date(minDate).setDate(new Date(minDate).getDate()+1))
            const maxDate = new Date(today)
            maxDate.setMonth(maxDate.getMonth() + 2)
            maxDate.setDate(15);
            return{
                title: "Hotel",
                items: [
                    {
                        // #TODO
                        text: "Jebsen Travel",
                    },
                    {
                        text: "Hotel",
                        active: true,
                    },
                ],
                modifyInput:false,
                autoCompleteData:[{
                    cityId: "Hello",
                    cityName: "Hello City"
                }],
                destinationKey:'',
                check_in_date:minDate,
                check_out_date:checkOutMinDate,
                cityId:'',
                room_selected:1,
                // destinationLabel:'',
                adult_selected:1,
                child_selected:0,
                earthData: [
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/17/177890/177890a_hb_r_001.jpg"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://media.roomstays.travel/www.miki.co.uk/live/hotel/mikiNet/image/v1.0/GB/20195/43/lr/1ex.jpg"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/10/103186/103186a_hb_a_001.jpg"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"http://media.roomstays.travel/photos.hotelbeds.com/giata/68/682128/682128a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/61/614491/614491a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/17/177890/177890a_hb_r_001.jpg"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://media.roomstays.travel/www.miki.co.uk/live/hotel/mikiNet/image/v1.0/GB/20195/43/lr/1ex.jpg"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/10/103186/103186a_hb_a_001.jpg"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"http://media.roomstays.travel/photos.hotelbeds.com/giata/68/682128/682128a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/61/614491/614491a_hb_l_001.jpg"
                    },
                ],
                destinationLabel:"Where Are You Going",
                checkInMinDate:minDate,
                checkOutMinDate:"",
                maxDate:maxDate,
                destinationsAutocomplete: [],
                selectedDestination: {
                    cityId: "",
                    cityName: ""
                },
                destinationValidate:false,
                destinationError:false
            }
        },
        methods:{
            ...mapActions("hotel", [
                "searchAvailHotels"
            ]),
            isLogin(){
                let a = new Date(sessionStorage.getItem('expiredTime'))
                let b = new Date()
                if(sessionStorage.getItem('accountInfo')!=null && a<b && sessionStorage.getItem('accountInfo')!=undefined && sessionStorage.getItem('accountInfo')!=""){
                    this.$router.push({
                        path:'/'
                    });
                    sessionStorage.removeItem('accountInfo');
                    sessionStorage.removeItem('loginTime');
                    sessionStorage.removeItem('expiredTime');
                    sessionStorage.removeItem('requestedCurrency')
                }
                else{
                    var sdate1 = new Date();
                    sdate1.setMinutes(sdate1.getMinutes() + 10);
                    sessionStorage.setItem('expiredTime',sdate1);
                }
            },

            fmtDate(obj){
                var date =  new Date(obj);
                var y = 1900+date.getYear();
                var m = "0"+(date.getMonth()+1);
                var d = "0"+date.getDate();
                return y+"-"+m.substring(m.length-2,m.length)+"-"+d.substring(d.length-2,d.length);
            },
            HotelCitySearch(){
                if(this.destinationValidate){
                    console.log('1111')
                    let checkInDate = this.fmtDate(this.check_in_date);
                    let checkOutDate = this.fmtDate(this.check_out_date);
                    let roomCount = this.room_selected;
                    let adultCount = this.adult_selected;
                    let childCount = this.child_selected;
                    let cityId = this.cityId;
                    let destinationLabel = this.destinationLabel
                    sessionStorage.setItem('checkInDate',checkInDate);
                    sessionStorage.setItem('checkOutDate',checkOutDate);
                    sessionStorage.setItem('roomCount',roomCount);
                    sessionStorage.setItem('adultCount',adultCount);
                    sessionStorage.setItem('childCount',childCount);
                    sessionStorage.setItem('cityId',cityId);
                    sessionStorage.setItem('hotelDestinationLabel',destinationLabel);
                    this.$router.push({
                        path:'/hotellist',
                        query:{
                            checkInDate: checkInDate,
                            checkOutDate:checkOutDate,
                            roomCount:roomCount,
                            adultCount:adultCount,
                            childCount:childCount,
                            cityId:cityId,
                            destinationLabel:destinationLabel
                        }
                    });
                }
                else if(!this.destinationValidate && this.modifyInput==false){
                    if(sessionStorage.getItem('cityId')!='null' && sessionStorage.getItem('cityId')!=null){
                        let checkInDate = this.fmtDate(this.check_in_date);
                        let checkOutDate = this.fmtDate(this.check_out_date);
                        let roomCount = this.room_selected;
                        let adultCount = this.adult_selected;
                        let childCount = this.child_selected;
                        let cityId = sessionStorage.getItem('cityId');
                        let destinationLabel = this.destinationLabel
                        sessionStorage.setItem('checkInDate',checkInDate);
                        sessionStorage.setItem('checkOutDate',checkOutDate);
                        sessionStorage.setItem('roomCount',roomCount);
                        sessionStorage.setItem('adultCount',adultCount);
                        sessionStorage.setItem('childCount',childCount);
                        sessionStorage.setItem('cityId',cityId);
                        sessionStorage.setItem('hotelDestinationLabel',destinationLabel);
                        this.$router.push({
                            path:'/hotellist',
                            query:{
                                checkInDate: checkInDate,
                                checkOutDate:checkOutDate,
                                roomCount:roomCount,
                                adultCount:adultCount,
                                childCount:childCount,
                                cityId:cityId,
                                destinationLabel:destinationLabel
                            }
                        });
                    }else{
                        this.destinationError = true
                    }
                }
                else{
                    this.destinationError = true
                }

            },
            selectDestintaion: function (key) {
                console.log(key);
                for (let i = 0; i < this.autoCompleteData.length; i++) {
                    if (key ==this.autoCompleteData[i].label){
                        this.cityId = this.autoCompleteData[i].cityId
                    }
                }
            },
            processDestination(item){
                this.modifyInput = true;
                if(JSON.stringify(item)==='{}'){
                    this.destinationValidate = false
                }else{
                    this.destinationError = false
                    this.destinationValidate = true
                    this.cityId = item.cityId
                    this.destinationLabel = item.label
                    console.log(this.destinationLabel)
                }
            },
            initiateFormData(){
                if(sessionStorage.getItem('checkInDate') && sessionStorage.getItem('hotelDestinationLabel')){
                    this.check_in_date = sessionStorage.getItem('checkInDate');
                    this.check_out_date = sessionStorage.getItem('checkOutDate')
                    this.room_selected = parseInt(sessionStorage.getItem('roomCount'))
                    this.adult_selected = parseInt(sessionStorage.getItem('adultCount'))
                    this.child_selected = parseInt(sessionStorage.getItem('childCount'))
                    this.destinationLabel = sessionStorage.getItem('hotelDestinationLabel')
                }
            }
        },
        computed:{
            ...mapState(["LayoutType", "Theme", "setHorizontalLayout"]),
            //调用vuex中 hotel modules的getAvailRS的状态信息
            ...mapGetters("hotel",[
                "getAvailRS"
            ]),
            adult_options:function () {
                return [
                    {
                        value:1,text:this.$t('adult_1')
                    },
                    {
                        value:2,text:this.$t('adult_2')
                    },
                    {
                        value:3,text:this.$t('adult_3')
                    },
                    {
                        value:4,text:this.$t('adult_4')
                    }
                ]
            },
            child_options:function () {
                return [
                    {
                        value:0,text:this.$t('child_0')
                    },
                    {
                        value:1,text:this.$t('child_1')
                    },
                    {
                        value:2,text:this.$t('child_2')
                    },
                    {
                        value:3,text:this.$t('child_3')
                    },
                    {
                        value:4,text:this.$t('child_4')
                    }
                ]
            },
            room_options:function () {
                return [
                    {
                        value:1,text:this.$t('room_1')
                    },
                    {
                        value:2,text:this.$t('room_2')
                    },
                    {
                        value:3,text:this.$t('room_3')
                    },
                    {
                        value:4,text:this.$t('room_4')
                    }
                ]
            },
            // destinationLabel:function () {
            //     if(sessionStorage.getItem('hotelDestinationLabel')!=null){
            //         return sessionStorage.getItem('hotelDestinationLabel')
            //     }else{
            //         return this.$t('whereAreYouGoing')
            //     }
            // }
            //compute属性无法修改this.destinationLabel
        },
        created(){
            // this.isLogin();
            this.initiateFormData();

        // hotelApi.getHotelPopularCities().then(res=>{
        //     console.log(res.data)
        // })
        },
        mounted() {
        },
        watch:{
            destinationKey: function() {
                this.$nextTick(function(){
                    hotelApi.getHotelDestinationAutocomplete(this.destinationKey).then(res=>{
                        this.autoCompleteData = [];
                        this.autoCompleteData = res.data;
                        console.log(this.autoCompleteData);
                        console.log(this.destinationKey)
                    })
                })
            },
            check_in_date:function () {
                this.$nextTick(function(){
                    if(this.check_in_date!=sessionStorage.getItem('checkInDate')){
                        console.log(this.check_in_date)
                        this.check_out_date = new Date(new Date(this.check_in_date).setDate(new Date(this.check_in_date).getDate()+1))
                    }
                })

            },

            '$i18n.locale':function () {
                this.$nextTick(function(){
                    console.log(this.$i18n.locale)
                    // this.$forceUpdate()
                    this.adult_options.splice(0, 1, this.adult_options[0])

                })

            },
        }
    }
</script>

<style scoped>

</style>