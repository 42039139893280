// import axios from "axios"
import axios from "../utils/axiosUtils"
const autocompleteUrl = "/api/v1/autocomplete";

function getAutocompleteDestination(url, key,locale){
    return axios.get(autocompleteUrl+url+"?language="+locale+"&cityOnly=true&keyword="+key);
}


/*
*   rail placed autocompleted 地点自动补全，
* @param keyword 自动补全的关键字
* @param language 自动补全的响应的语言类型
* @return 符合搜索条件的地点数组，地点为上车或下车信息
*/
function railPlaces(keyword, language) {
    if (language==undefined || language =="")language="en";
    let url = autocompleteUrl+"/rail-places?language="+language+"&keyword="+keyword;
    return axios.get(url);
}

function getCountries(keyword, language) {
    if (language==undefined || language =="")language="en";
    let url = "/api/v1/autocomplete/country-code?type=http&language="+language+"&keyword="+keyword;
    return axios.get(url,{
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export default {
    railPlaces,
    getAutocompleteDestination,
    getCountries
}